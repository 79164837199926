




















import { Component } from 'vue-property-decorator'
import { prodProcessService } from '@/services'
import LandingWidget from '@/components/organsims/widgets/Landing.vue'
import ArticleWidget from '@/components/organsims/widgets/Article.vue'
import GridboxWidget from '@/components/organsims/widgets/Gridbox.vue'
import TooltipWidget from '@/components/organsims/widgets/Tooltip.vue'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'
import Layout from '@/components/organsims/Layout.vue'
import View from '@/mixins/View'

@Component({
  components: {
    LandingWidget,
    ArticleWidget,
    GridboxWidget,
    TooltipWidget,
    CoverWidget,
    Layout,
  },
})
export default class ProdProcess extends View {
  widgets: any = []

  getWidgetId(widget: any, index: number) {
    return widget.tooltip ? `tooltip-${index}` : undefined
  }

  scroll() {
    if (!this.$state.needsLogo && !this.$state.smoothScroll) this.$state.needsLogo = true
  }

  async created() {
    this.widgets = await this.fetch(prodProcessService)
  }
}
