







































import { gsap } from 'gsap'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TooltipWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean

  panelOpen = false

  tooltip = null as any

  @Watch('intersect')
  onIntersectChange(intersect: boolean) {
    if (!intersect) {
      this.closePanel()
    }
  }

  scrollTo() {
    const { index } = this.tooltip
    const id = `tooltip-${index}`
    this.$state.needsLogo = false
    this.$fn.scrollTo(id)
  }

  openPanel(tooltip: any) {
    this.tooltip = tooltip
    this.panelOpen = true
  }

  closePanel() {
    this.panelOpen = false
  }

  panelEnter(el: HTMLElement, done: () => void) {
    const close = el.querySelector('.close')
    const title = el.querySelector('h3')
    const text = el.querySelector('p')
    const btn = el.querySelector('.cta')
    gsap
      .timeline({ onComplete: done, defaults: { duration: 0.4, ease: 'power2.out' } })
      .fromTo(el, { autoAlpha: 0 }, { autoAlpha: 1 }, '<')
      .fromTo(
        [close, title, text, btn],
        { autoAlpha: 0, y: 40 },
        { autoAlpha: 1, y: 0, stagger: 0.08 },
        '<+.08'
      )
  }

  panelLeave(el: HTMLElement, done: () => void) {
    gsap
      .timeline({ onComplete: done, defaults: { duration: 0.6, ease: 'power2.out' } })
      .to(el, { autoAlpha: 0 })
  }
}
