











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Select extends Vue {
  @Prop()
  options!: any

  value = -1
}
