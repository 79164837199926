























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  filters: {
    toDays: (time: string) =>
      `0${Math.floor(parseFloat(time) / (1000 * 60 * 60 * 24))}`.slice(-2),
    toHours: (time: string) =>
      `0${Math.floor((parseFloat(time) / (1000 * 60 * 60)) % 24)}`.slice(-2),
    toMinutes: (time: string) => `0${Math.floor((parseFloat(time) / 1000 / 60) % 60)}`.slice(-2),
    toSeconds: (time: string) => `0${Math.floor((parseFloat(time) / 1000) % 60)}`.slice(-2),
  },
})
export default class Countdown extends Vue {
  @Prop() deadline!: number

  timer!: NodeJS.Timeout

  time = 0

  get endtime() {
    return new Date(this.deadline).getTime()
  }

  update() {
    this.time = this.endtime - new Date().getTime()
    if (this.time <= 0) this.stop()
  }

  start() {
    this.timer = setInterval(this.update, 1000)
  }

  stop() {
    this.clear()
    this.time = 0
  }

  clear() {
    clearInterval(this.timer)
  }

  mounted() {
    this.start()
  }

  beforeDestroy() {
    this.clear()
  }
}
