

























import { Component, Vue, Prop } from 'vue-property-decorator'
import Countdown from '@/components/molecules/Countdown.vue'
import Carousel from '@/components/molecules/Carousel.vue'
import Display from '@/components/molecules/Display.vue'
import Select from '@/components/atoms/form/Select.vue'
import Poster from '@/components/atoms/Poster.vue'

@Component({
  components: {
    Countdown,
    Carousel,
    Display,
    Poster,
    Select,
  },
})
export default class ArticleWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean

  get needsSelect() {
    return !!this.model.select
  }

  get needsCountdown() {
    return !!this.model.countdown
  }

  get needsCarousel() {
    const { images } = this.model
    return !!images && images.length > 1
  }
}
